(function() { 'use strict';

  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 480;
  window.MOBILE_WIDTH = 800;
  window.TABLET_WIDTH = 950;
  window.SMALL_NOTEBOOK_WIDTH = 1200;
  window.NOTEBOOK_WIDTH = 1400;
  window.HEADER_HEIGHT = $('.header').height();

  // selectors
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);

  // tosrus default settings
  window.TOSRUS_DEFAULTS = {
    buttons: {
      next: true,
      prev: true
    },

    keys: {
      prev: 37,
      next: 39,
      close: 27
    },

    wrapper: {
      onClick: 'close'
    }
  };


  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');


  // Helpers
  // -------

  window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
  window.WINDOW_HEIGHT = $WINDOW.height();
  $WINDOW.resize(function() {
    WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    WINDOW_HEIGHT = $WINDOW.height();
  });

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH > NOTEBOOK_WIDTH;
  }
  window.IS_NOTEBOOK_WIDTH = function() {
    return ( WINDOW_WIDTH > SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= NOTEBOOK_WIDTH );
  }
  window.IS_SMALL_NOTEBOOK_WIDTH = function() {
    return ( WINDOW_WIDTH > TABLET_WIDTH && WINDOW_WIDTH <= SMALL_NOTEBOOK_WIDTH );
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH > MOBILE_WIDTH && WINDOW_WIDTH <= TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= MOBILE_WIDTH;
  }
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
  }
  window.IS_TOUCH_DEVICE = 'ontouchstart' in document;


  // Masked input
  // ------------

  if (IS_DESKTOP) {
    $('input[type="date"]').attr('type', 'text');

    // Phone
    $('input[type="tel"]').mask('+7 (999) 999-99-99', {
      autoclear: false
    });

    // Date
    $('.js-date-mask').mask('99/99/9999', {
      placeholder: 'дд.мм.гггг',
      autoclear: false
    });

    // Time
    $('.js-time-mask').mask('99:99', {
      placeholder: 'чч:мм',
      autoclear: false
    });
  }

  // Overlay loader
  // --------------

  // open popup
  $DOCUMENT.on('click.overlay-open', '.js-overlay', function(e) {
    e.preventDefault();
    $.overlayLoader(true, $($(this).attr('href')));
  });

  // autofocus
  $DOCUMENT.on('overlayLoaderShow', function(e, $node) {
    $node.find('.js-autofocus-inp').focus();
  });

  // close popup
  $DOCUMENT.on('click.overlay-close', '.js-popup-close', function(e) {
    e.preventDefault();
    $.overlayLoader(false, $(this).closest('.js-popup'));
  });


  // Selectric
  // ---------

  // init selectric
  $DOCUMENT.on('initSelectric yiiListViewUpdated', function() {
    $('select').selectric({
      disableOnMobile: true
    });
  }).trigger('initSelectric');


  // Scroll to
  // ---------

  $DOCUMENT.on('click.scroll-to', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this);
    var $elemToScroll = $($lnk.attr('href'));
    var speed = $lnk.data('speed') || 150;
    var offset = $lnk.data('offset') || 0;

    $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
  });


  // Menus
  // -----

  (function() { // Чтобы потом с тачем не запариваться
    var $menus = $('.js-menu');

    if (IS_DESKTOP) {
      $menus.on('mouseenter.js-menu', 'li', function() {
        var self = $(this);
        clearTimeout(self.data('hoverTimeout'));
        self.addClass('is-hovered');
      });

      $menus.on('mouseleave.js-menu', 'li', function() {
        var self = $(this);
        self.data('hoverTimeout', setTimeout(function() {
          self.removeClass('is-hovered');
        }, 200));
      });
    }

    if (IS_MOBILE) {
      $menus.on('click.js-m-menu', 'a', function(e) {
        e.preventDefault();

        var $anchor = $(this);
        var $parent = $anchor.parent();

        var isWithDropdown = $parent.hasClass('with-dropdown');
        var isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings().removeClass('is-hovered');

        if (!isWithDropdown) {
          location.href = $anchor.attr('href');
        } else {
          if (isOnHover) {
            location.href = $anchor.attr('href');
          } else {
            $parent.addClass('is-hovered');
          }
        }
      });
    }
  }());


  // Tabs
  // ----

  $('.js-tabs .tabs-nav li a').click(function(e) {
    e.preventDefault();

    var $self = $(this);
    var $panel = $( $self.attr('href') );

    $self.closest('li').addClass('active').siblings().removeClass('active');
    $panel.closest('.tabs').find('.tabs-panel').hide();
    $panel.fadeIn();
  });


  // Galleries
  // ---------

  // init tosrus static gallery
  $('.js-gallery').each(function() {
    $(this).find('.js-gallery-item').tosrus(TOSRUS_DEFAULTS);
  });


  // Rotators
  // --------

  $('.js-slideshow').each(function() {
    var $self = $(this);

    var tos = $self.tosrus({
      effect: 'slide',
      slides: {
        visible: 1
      },
      autoplay: {
        play: true,
        timeout: 7500
      },
      infinite: true,
      pagination: {
        add: true
      }
    });
  });


  // Scrolling to top
  // ----------------

  if ( !IS_MOBILE_WIDTH() ) {
    var goTopBtn = $('<div class="go-top-btn"></div>');
    goTopBtn.click(function() {
      $WINDOW.scrollTo(0, 200);
    });
    $WINDOW.scroll(function() {
      var scrollTop = $WINDOW.scrollTop();
      if ( scrollTop > 0 ) {
        goTopBtn.addClass('visible');
      } else {
        goTopBtn.removeClass('visible');
      }
    });
    $BODY.append( goTopBtn );
  }

  // Ya share
  var YaShare = Ya.share2('js-ya-sharing');

  // Fullscreen menu
  // ---------------

  var $body = $('body'),
      $menuOverlay = $('.js-menu-fullscreen-overlay'),
      $hamburger = $('.js-hamburger'),
      $cross = $menuOverlay.find('.js-header-cross'),
      $menuFullscreen = $menuOverlay.find('.js-menu-fullscreen'),
      $menuTitle = $menuOverlay.find('.menu-fullscreen-category-title'),
      $menuCategory = $menuOverlay.find('.js-menu-fullscreen-category-links'),
      $menuSocials = $menuOverlay.find('.menu-fullscreen-social-networks-wrapper'),
      $menuMainTitle = $menuOverlay.find('.section-title-right');

  $body.on('click', '.js-hamburger', function() {
    $menuOverlay.css({
      visibility: 'visible',
      opacity: 1,
      transform: 'translate(0, 0)',
    });
    $body.css('overflowY', 'hidden');
    $WINDOW.off('mousewheel', sectionScroll);
    setTimeout(function(){
      $menuMainTitle.css({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      });
      $menuTitle.css({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      });
      $menuCategory.css({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      });
      $menuSocials.css({
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      });
      // $menuCategory.each(function(i){
      //   var $this = $(this),
      //       $elements = $this.find('li a');
      //   $elements.each(function(i){
      //     var $this = $(this);
      //     setTimeout(function(){
      //       $this.css({
      //         opacity: 1,
      //         transform: 'translate3d(0, 0, 0)'
      //       });
      //     }, 20 * i);
      //   })
      // });
      $menuFullscreen.addClass('show-separator');
    }, 200);
  });

  $body.on('click', '.js-header-cross', function() {
    $menuOverlay.css({
      visibility: 'hidden',
      opacity: 0,
      transform: 'translate(0, -100%)',
    });
    $body.css('overflowY', 'scroll');
    $WINDOW.on('mousewheel', sectionScroll);
    setTimeout(function() {
      $menuMainTitle.css({
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
      });
      $menuTitle.css({
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)'
      });
      $menuCategory.css({
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
      });
      $menuSocials.css({
        opacity: 0,
        transform: 'translate3d(0, 50px, 0)',
      });
      $menuFullscreen.removeClass('show-separator');
    }, 200);
  });

  // Photo gallery
  // -------------

  var $galleryGrid = $('.js-gallery-grid-wrapper');

  $galleryGrid.imagesLoaded()
    .done( function() {
      $galleryGrid.masonry({
        itemSelector: '.js-gallery-item-photo',
        columnWidth: '.js-gallery-grid-sizer',
        gutter: 22,
        percentPosition: true,
      });
    });

  $('.js-gallery-wrapper .js-gallery-filter').on('click', 'a', function(e) {
    e.preventDefault();

    var $this = $(this),
        tag = $this.data('filter-tag');
    filterPhotoByTag(tag);
    $this.closest('li').addClass('active').siblings().removeClass('active');

    $WINDOW.scrollTo(0, 200);
  });

  function filterPhotoByTag(tag) {

    var items = $('.js-gallery-item-photo');

    if (tag == 'all') {
      items.each(function() {
        $(this).addClass('js-gallery-item-photo-visible');
      });
      $galleryGrid.masonry('layout');
      return;
    }

    items.each(function() {
      var $this = $(this);

      if ( $this.attr('data-filter-tags').indexOf(tag) == -1 ) {
        $this.removeClass('js-gallery-item-photo-visible');
      } else {
        $this.addClass('js-gallery-item-photo-visible');
      }
    });

    $galleryGrid.masonry('layout');

  }

  // Video gallery
  // -------------

  var $videoGalleryGrid = $('.js-video-inner-wrapper');

  $videoGalleryGrid.imagesLoaded()
    .done( function () {
      $videoGalleryGrid.masonry({
        itemSelector: '.js-video-block',
        columnWidth: '.js-video-grid-sizer',
        percentPosition: true,
      });
    });

  $('.js-video-gallery-wrapper .js-gallery-filter').on('click', 'a', function(e) {
    e.preventDefault();

    var tag = $(this).data('filter-tag');
    filterVideoByTag(tag);
    $this.closest('li').addClass('active').siblings().removeClass('active');

    $WINDOW.scrollTo(0, 200);
  });

  function filterVideoByTag(tag) {

    var items = $('.js-video-block');

    if (tag == 'all') {
      items.each(function() {
        $(this).addClass('js-video-block-visible');
      });
      $videoGalleryGrid.masonry('layout');
      return;
    }

    items.each(function() {
      var $this = $(this);

      if ( $this.attr('data-filter-tags').indexOf(tag) == -1 ) {
        $this.removeClass('js-video-block-visible');
      } else {
        $this.addClass('js-video-block-visible');
      }

      $videoGalleryGrid.masonry('layout');

    });

  }

  if ( IS_SMALL_MOBILE_WIDTH() ) {
    $('.js-gallery-item-photo a').tosrus();
  }

  // Photo popup
  // -------------
  var $photoPopUpOverlay = $('.js-photo-popup-overlay'),
      $photoPopUpImgInner = $('.js-photo-popup-image-inner'),
      $photoPopUpImg = $('.js-photo-popup-image'),
      $photoPopUpBorder = $('.js-photo-popup-image-border'),
      $photoPopUpHeading = $('.js-photo-popup-heading'),
      $photoPopUpDescription = $('.js-photo-popup-description'),
      urlHash = window.location.hash;

  $('.js-gallery-item-photo').on('click', 'a', function(e) {
    if ( IS_SMALL_MOBILE_WIDTH() ) return; // Для мобильных делаем обычную галерею

    e.preventDefault();

    var $this = $(this),
        style = $this.attr('style'),
        urlPos = style.indexOf('/'),
        imgSrc = style.slice(urlPos, style.length - 1),
        imgSize =  $this.parent().data('image-size'),
        heading = $this.parent().data('heading'),
        description = $this.parent().data('description') || '',
        urlPostfix = $this.parent().attr('id'),
        currentUrl = window.location.href;

    $this.closest('.js-gallery-item-photo').addClass('active');

    $photoPopUpHeading.text(heading);
    $photoPopUpDescription.text(description);
    YaShare.updateContent({
      url: currentUrl.split('#', 1)[0] + '#' + urlPostfix,
      image: document.location.host + imgSrc,
      title: heading
    });

    $photoPopUpImg.attr('class', '');
    if (imgSize == 'vertical') {
      $photoPopUpImg.addClass('js-photo-popup-image-vertical');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper-square');
      $photoPopUpImgInner.css({
        marginTop: 0,
      })
    } else if (imgSize == 'horizontal') {
      $photoPopUpImg.addClass('js-photo-popup-image-horizontal');
      $photoPopUpHeading.addClass('js-photo-popup-heading-upper');
      $photoPopUpImg.removeClass('js-photo-popup-image-vertical');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper-square');
      $photoPopUpImgInner.css({
        marginTop: 92,
      })
    } else if (imgSize == 'square') {
      $photoPopUpHeading.addClass('js-photo-popup-heading-upper-square');
      $photoPopUpImg.addClass('js-photo-popup-image-square');
      $photoPopUpImgInner.css({
        marginTop: 92,
      })
    }

    $photoPopUpOverlay.css({
      visibility: 'visible',
      opacity: 1,
      transform: 'translate(0, 0)',
    });

    $photoPopUpImg.attr('src', imgSrc);

    setTimeout(function() {
      $photoPopUpImgInner.css({
        visibility: 'visible',
        opacity: 1,
        transform: 'translate(0, 0)',
      });
      setTimeout(function() {
        $photoPopUpHeading.css({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        });
      }, 220);
    }, 220);

    setTimeout(function() {
      $photoPopUpBorder.each(function(i) {
        var $this = $(this);
        var animTime = 150;

        setTimeout(function() {
          if ((i + 1) % 2 != 0) {
            $this.animate({
              width: '100%'
            }, animTime);
          } else {
            $this.animate({
              height: '100%'
            }, animTime);
          }
        }, i * animTime);

      });
    }, 440);

    $body.css({overflowY: 'hidden'});

  });

  $('.js-photo-popup-next, .js-photo-popup-prev').click(function() {
    var $this = $(this),
        $currentPhoto = $('.js-gallery-item-photo.active'),
        $nextPhoto;

    if ( $this.hasClass('js-photo-popup-next') ) {
      $nextPhoto = $currentPhoto.next('.js-gallery-item-photo-visible');
    } else if ( $this.hasClass('js-photo-popup-prev') ) {
      $nextPhoto = $currentPhoto.prev('.js-gallery-item-photo-visible');
    }

    if ( $nextPhoto.length == 0 ) return;

    var $nextPhotoLink = $nextPhoto.find('a'),
        style = $nextPhotoLink.attr('style'),
        urlPos = style.indexOf('/'),
        imgSrc = style.slice(urlPos, style.length - 1),
        imgSize =  $nextPhotoLink.parent().data('image-size'),
        heading = $nextPhotoLink.parent().data('heading'),
        description = $nextPhotoLink.parent().data('description'),
        urlPostfix = $nextPhotoLink.parent().attr('id'),
        currentUrl = window.location.href;

    $currentPhoto.removeClass('active');
    $nextPhoto.addClass('active');

    $photoPopUpHeading.text(heading);
    $photoPopUpDescription.text(description);
    YaShare.updateContent({
      url: currentUrl.split('#', 1)[0] + '#' + urlPostfix,
      image: document.location.host + imgSrc,
      title: heading
    });

    $photoPopUpImg.attr('class', '');
    if (imgSize == 'vertical') {
      $photoPopUpImg.addClass('js-photo-popup-image-vertical');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper-square');
      $photoPopUpImgInner.css({
        marginTop: 0,
      })
    } else if (imgSize == 'horizontal') {
      $photoPopUpImg.addClass('js-photo-popup-image-horizontal');
      $photoPopUpHeading.addClass('js-photo-popup-heading-upper');
      $photoPopUpImg.removeClass('js-photo-popup-image-vertical');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper-square');
      $photoPopUpImgInner.css({
        marginTop: 92,
      })
    } else if (imgSize == 'square') {
      $photoPopUpHeading.addClass('js-photo-popup-heading-upper-square');
      $photoPopUpImg.addClass('js-photo-popup-image-square');
      $photoPopUpImgInner.css({
        marginTop: 92,
      })
    }

    $photoPopUpImg.attr('src', imgSrc);
  });

  function openPhotoPopUpByHash(urlHash) {
    var $this = $(urlHash),
        style = $this.children().attr('style'),
        urlPos = style.indexOf('/'),
        imgSrc = style.slice(urlPos, style.length - 1),
        imgSize = $this.data('image-size'),
        heading = $this.data('heading'),
        currentUrl = window.location.href;

    $this.addClass('active');

    $photoPopUpHeading.text(heading);
    YaShare.updateContent({
      url: currentUrl,
      image: document.location.host + imgSrc,
      title: heading
    });

    if (imgSize == 'vertical') {
      $photoPopUpImg.addClass('js-photo-popup-image-vertical');
      $photoPopUpHeading.removeClass('js-photo-popup-heading-upper');
      $photoPopUpImgInner.css({
        marginTop: 0,
      })
    } else if (imgSize == 'horizontal') {
      $photoPopUpImg.addClass('js-photo-popup-image-horizontal');
      $photoPopUpHeading.addClass('js-photo-popup-heading-upper');
      $photoPopUpImgInner.css({
        marginTop: 92,
      })
    } else if (imgSize == 'square') {
      $photoPopUpHeading.addClass('js-photo-popup-heading-upper');
      $photoPopUpImg.addClass('js-photo-popup-image-square');
      $photoPopUpImgInner.css({
        marginTop: 92,
      })
    }

    $photoPopUpOverlay.css({
      visibility: 'visible',
      opacity: 1,
      transform: 'translate(0, 0)',
    });

    $photoPopUpImg.attr('src', imgSrc);

    setTimeout(function() {
      $photoPopUpImgInner.css({
        visibility: 'visible',
        opacity: 1,
        transform: 'translate(0, 0)',
      })
      setTimeout(function() {
        $photoPopUpHeading.css({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        });
      }, 220);
    }, 220);

    setTimeout(function() {
      $photoPopUpBorder.each(function(i) {
        var $this = $(this);
        var animTime = 150;

        setTimeout(function() {
          if ((i + 1) % 2 != 0) {
            $this.animate({
              width: '100%'
            }, animTime);
          } else {
            $this.animate({
              height: '100%'
            }, animTime);
          }
        }, i * animTime);

      });
    }, 440);

    $body.css({overflowY: 'hidden'});
  }

  $('.js-photo-popup-overlay').on('click', '.js-photo-popup-cross', function() {

    $photoPopUpOverlay.css({
      visibility: 'hidden',
      opacity: 0,
      transform: 'translate(0, -100%)',
    });

    setTimeout(function() {
      $photoPopUpImgInner.css({
        visibility: 'hidden',
        opacity: 0,
        transform: 'translate(0, 100%)',
      });
      $photoPopUpHeading.css({
        opacity: 0,
        transform: 'translate3d(-50px, 0, 0)',
      });
      $photoPopUpBorder.each(function(i) {
        var $this = $(this);
        if ((i + 1) % 2 != 0) {
          $this.css({
            width: '0'
          });
        } else {
          $this.css({
            height: '0'
          });
        }
      });
    }, 220);

    $body.css({overflowY: 'scroll'});

  });

  // Video popup

  var $videoBlock = $('.js-video-block'),
      $perfomanceDescVideo = $('.perfomance-description-video'),
      $videoPopUpOverlay = $('.js-video-popup-overlay'),
      $YTPlayer = $videoPopUpOverlay.find('.js-youtube-player'),
      YTplayerSettings = '?rel=0&enablejsapi=1&version=3&playerapiid=ytplayer&autoplay=1',
      $videoPopUpDesc = $videoPopUpOverlay.find('.js-video-popup-desc'),
      $videoPopUpHeading = $videoPopUpOverlay.find('.js-video-popup-heading'),
      $videoPopUpSharing = $videoPopUpOverlay.find('.js-video-popup-sharing-social-networks');

  function openVideoPopUpByHash(urlHash) {
    var $this = $('[data-hash=' + urlHash.slice(1) + ']'),
        link = $this.attr('href'),
        desc = $this.data('desc'),
        heading = $this.data('heading'),
        urlPostfix = $this.data('hash'),
        currentUrl = window.location.href;

    $YTPlayer.attr('src', link + YTplayerSettings);
    $videoPopUpDesc.text(desc);
    $videoPopUpHeading.text(heading);
    YaShare.updateContent({
      url: currentUrl,
      title: heading,
      description: desc
    });

    $videoPopUpOverlay.css({
      visibility: 'visible',
      opacity: 1,
      transform: 'translate(0, 0)',
    });

  }

  $videoBlock.on('click', 'a', openVideoPopUp);
  $perfomanceDescVideo.on('click', 'a', openVideoPopUp);

  function openVideoPopUp(e) {
    e.preventDefault();

    var $this = $(this),
        link = $this.attr('href'),
        desc = $this.data('desc'),
        heading = $this.data('heading'),
        urlPostfix = $this.data('hash'),
        currentUrl = window.location.href;

    $YTPlayer.attr('src', link + YTplayerSettings);
    $videoPopUpDesc.text(desc);
    $videoPopUpHeading.text(heading);
    YaShare.updateContent({
      url: currentUrl.split('#', 1)[0] + '#' + urlPostfix,
      title: heading,
      description: desc
    });

    $videoPopUpOverlay.css({
      visibility: 'visible',
      opacity: 1,
      transform: 'translate(0, 0)',
    });

  }

  $videoPopUpOverlay.on('click', '.js-video-popup-cross', function() {

    //при закрытии поп-апа выключаем плеер
    $YTPlayer[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

    $videoPopUpOverlay.css({
      visibility: 'hidden',
      opacity: 0,
      transform: 'translate(0, -100%)',
    });

  });

  // Открыть поп-ап, если в урле содержится нужный хеш
  if (urlHash !== '') {
    if (urlHash.indexOf('#video-') >= 0) {
      openVideoPopUpByHash(urlHash);
    } else if (urlHash.indexOf('#photo-') >= 0) {
      openPhotoPopUpByHash(urlHash);
    }
    // } else {
    //   console.log('do nothing');
    // }
  }

  // Background slider

  $('.background-slider').tosrus({
    autoplay: {
      play: true
    },
    buttons: {
      prev: $('.background-slider-arrow-prev'),
      next: $('.background-slider-arrow-next'),
    }
  });

  // Affiche slider

  var $eventSlider = $('.js-event-slider'),
      $eventSlides = $eventSlider.find('.js-event-slides'),
      $eventSlide = $eventSlides.find('.js-event-slide'),
      eventSlideWidth = $eventSlider.width(),
      eventSlideAmount = $eventSlide.length,
      eventSlidesWidth = eventSlideWidth * eventSlideAmount;

  $eventSlides.width(eventSlidesWidth);

  $body.on('click', '.js-event-controls', eventSliderHandler);

  function eventSliderHandler() {
    var $this = $(this);

    $body.off('click', '.js-event-controls', eventSliderHandler);

    var sliderDelay = setTimeout(function(){
      $body.on('click', '.js-event-controls', eventSliderHandler);
    }, 300);

    if ($this.hasClass('js-event-control-prev')) {
      prevEventSlide();

      function prevEventSlide() {
        var currentPos = $eventSlides.position().left;
        if ( currentPos == 0 ) {
          clearTimeout(sliderDelay);
          $body.on('click', '.js-event-controls', eventSliderHandler);
          return false;
        }
        $('.js-event-slide-active').removeClass('js-event-slide-active').prev().addClass('js-event-slide-active');
        $eventSlides.animate({
          left: currentPos + eventSlideWidth
        }, 300);
      }

    } else if ($this.hasClass('js-event-control-next')) {
      nextEventSlide();

      function nextEventSlide() {
        var currentPos = $eventSlides.position().left;
        if ( currentPos == -eventSlideWidth * (eventSlideAmount - 1) ) {
          clearTimeout(sliderDelay);
          $body.on('click', '.js-event-controls', eventSliderHandler);
          return false;
        }
        $('.js-event-slide-active').removeClass('js-event-slide-active').next().addClass('js-event-slide-active');
        $eventSlides.animate({
          left: currentPos - eventSlideWidth
        }, 300);
      }
    }

    $('.js-event-slide-active').next().find('.js-event-info').css({
      transform: 'translate(15%, 0)'
    });
    $('.js-event-slide-active').prev().find('.js-event-info').css({
      transform: 'translate(-15%, 0)'
    });

  }


  // Affiche table slider
  var $afficheCalendarSlider = $('.js-affiche-calendar-slider'),
      $afficheCalendarSlides = $afficheCalendarSlider.find('.js-affiche-calendar-slides'),
      $afficheCalendarSlide = $afficheCalendarSlides.find('.js-affiche-calendar-slide'),
      afficheCalendarSlideWidth = $afficheCalendarSlide.width(),
      afficheCalendarSlideAmount = $afficheCalendarSlide.length,
      currentSlide = 0,
      //calendar date slider
      $calendarDateSlider = $('.js-calendar-date-slider'),
      $calendarDateSlides = $calendarDateSlider.find('.js-calendar-date-slides'),
      $calendarDateSlide = $calendarDateSlides.find('.js-calendar-date-slide'),
      calendarDateSlideWidth = $calendarDateSlide.width(),
      calendarDateSlideAmount = $calendarDateSlide.length;

  $calendarDateSlides.width(calendarDateSlideWidth * calendarDateSlideAmount);
  $afficheCalendarSlides.width(afficheCalendarSlideWidth * afficheCalendarSlideAmount);


  $(window).resize(function() {
    var $this = $(this);

    if ($this.width() <= 1600) {
      $afficheCalendarSlides.css({
        width: 'calc((100vw - 37px) * ' + afficheCalendarSlideAmount + ')',
        left: 'calc((100vw - 37px) * ' + -currentSlide + ')',
      })
    } else {
      $afficheCalendarSlides.css({
        width: 1600 * afficheCalendarSlideAmount,
        left: 1600 * -currentSlide,
      });
    }

  });

  $body.on('click','.js-calendar-date-arrow', afficheTableSliderHandler);

  function afficheTableSliderHandler() {
    var $this = $(this);

    afficheCalendarSlideWidth = $afficheCalendarSlide.width();

    $body.off('click', '.js-calendar-date-arrow', afficheTableSliderHandler);

    var sliderDelay = setTimeout(function(){
      $body.on('click', '.js-calendar-date-arrow', afficheTableSliderHandler);
    }, 300);

    if ($this.hasClass('js-calendar-date-arrow-next')) {

      afficheCalendarSlideNext();
      calendarDateSlideNext();

      function calendarDateSlideNext() {
        var currentPos = $calendarDateSlides.position().left;
        if (currentPos == -calendarDateSlideWidth * (calendarDateSlideAmount - 1)) {
          return false;
        }
        $calendarDateSlides.animate({
          left: currentPos - calendarDateSlideWidth
        }, 300);
      }

      function afficheCalendarSlideNext() {
        var currentPos = $afficheCalendarSlides.position().left;
        if ( currentPos == -afficheCalendarSlideWidth * (afficheCalendarSlideAmount - 1)) {
          clearTimeout(sliderDelay);
          $body.on('click', '.js-calendar-date-arrow', afficheTableSliderHandler);
          return false;
        }

        currentSlide += 1;
        if ($window.width() >= 1600) {
          $afficheCalendarSlides.css({
            left: 1600 * -currentSlide
          });
        } else {
          $afficheCalendarSlides.css({
            left: 'calc((100vw - 37px) * ' + -currentSlide + ')'
          });
        }

      }

    } else if ($this.hasClass('js-calendar-date-arrow-prev')) {

      afficheCalendarSlidePrev();
      calendarDateSlidePrev();

      function calendarDateSlidePrev() {
        var currentPos = $calendarDateSlides.position().left;
        if (currentPos == 0) {
          return false;
        }
        $calendarDateSlides.animate({
          left: currentPos + calendarDateSlideWidth
        }, 300);
      }

      function afficheCalendarSlidePrev() {
        var currentPos = $afficheCalendarSlides.position().left;
        if ( currentPos == 0) {
          clearTimeout(sliderDelay);
          $body.on('click', '.js-calendar-date-arrow', afficheTableSliderHandler);
          return false;
        }

        currentSlide -= 1;
        if ($window.width() >= 1600) {
          $afficheCalendarSlides.css({
            left: 1600 * -currentSlide
          });
        } else {
          $afficheCalendarSlides.css({
            left: 'calc((100vw - 37px) * ' + -currentSlide + ')'
          });
        }
      }

    }
  }

  // Affiche toggle
  var $afficheSection = $('.js-affiche-section');
  $body.on('click', '.js-affiche-toggle-btn', function(e) {
    e.preventDefault();

    var $this = $(this);
    if ($this.data('affiche-view') == 'feed') {

      $this.data('affiche-view', 'calendar').text('Лента');
      $afficheSection.addClass('js-calendar-view');

    } else if ($this.data('affiche-view') == 'calendar') {

      $this.data('affiche-view', 'feed').text('Календарь');
      $afficheSection.removeClass('js-calendar-view');

    }
  });

  // Theater toggle view
  var $theaterAffiche = $('.theater-perfomance-affiche-wrapper'),
      $theaterList = $('.theater-perfomance-list-wrapper'),
      $theaterAfficheBtn = $('.js-theater-view-affiche'),
      $theaterListBtn = $('.js-theater-view-list');
  $body.on('click', '.js-theater-view-list', function(e) {
    e.preventDefault();

    if ($theaterList.hasClass('active')) return;

    $theaterAfficheBtn.removeClass('selected');
    $theaterListBtn.addClass('selected');

    $theaterAffiche.removeClass('active');
    $theaterList.addClass('active');

  });

  $body.on('click', '.js-theater-view-affiche', function(e) {
    e.preventDefault();

    if ($theaterAffiche.hasClass('active')) return;

    $theaterListBtn.removeClass('selected');
    $theaterAfficheBtn.addClass('selected');

    $theaterList.removeClass('active');
    $theaterAffiche.addClass('active');

  });

  // Scrollbar

  $('.news-article-content').perfectScrollbar();
  $('.news-announces').perfectScrollbar();
  $('.perfomance-description-text').perfectScrollbar();
  if ( IS_SMALL_MOBILE_WIDTH() ) {
    $('.js-event-info').perfectScrollbar();
  }

  // News zoom
  $('.js-news-zoom').tosrus();

  // Timelne

  var $timeline = $('.timeline'),
      $timelinePoints = $timeline.find('.timeline-point');

  $timeline.on('click', '.timeline-point', function() {

    var $this = $(this);

    if ( !$this.hasClass('timeline-point-active') && !$this.hasClass('timeline-point-back')) {

      $this.siblings().removeClass('timeline-point-active');
      $this.addClass('timeline-point-active');

    } if ( $this.hasClass('timeline-point-back') ) {

      var $timelineActive = $timeline.find('.timeline-point-active');
      if ( !$timelineActive.prev().hasClass('timeline-point-back') ) {

        $timelineActive.prev().addClass('timeline-point-active');
        $timelineActive.removeClass('timeline-point-active');

      }

    }

  });


  // Section scrolling

  var $window = $(window),
      $htmlBody = $('html, body'),
      $sections = $('.scroll-section'),
      sectionsPos = [],
      nextSectionIndex = 0,
      scrollTop = $window.scrollTop();

  function findSectionsPos(sections) {
    var arr = [];
    for (var i = 0; i < sections.length; i++) {
      arr.push(parseInt(sections[i].offsetTop));
    }
    return arr;
  }

  $(function() {
    sectionsPos = findSectionsPos($sections);
  });

  $window.on('resize', function() {
    sectionsPos = findSectionsPos($sections);
  });

  for (var i = 0; i < sectionsPos.length; i++) {
    if (scrollTop <= sectionsPos[i]) {
      nextSectionIndex = i;
      break;
    }
  }
  $sections.removeClass('inview');
  $sections.eq(nextSectionIndex).addClass('inview');
  if ( $('.main-section.inview').length || $('.perfomance-section-title.inview').length ) {
    $('.header-hamburger').addClass('white');
  } else {
    $('.header-hamburger').removeClass('white');
  }

  function sectionScroll(e) {
    var scrollTop = $window.scrollTop(),
        $this = $(this),
        animDuration = 300,
        nextSectionPos,
        nextSectionIndex = null;

    if (e.deltaY < 0) {
      for (var i = 0; i < sectionsPos.length; i++) {
        if (scrollTop < sectionsPos[i]) {
          nextSectionPos = sectionsPos[i];
          nextSectionIndex = i;
          break;
        }
      }
      if (nextSectionPos === undefined) return;
      $htmlBody.animate({
        scrollTop: nextSectionPos,
      }, animDuration);
    } else {
      for (var i = sectionsPos.length - 1; i >= 0; i--) {
        if (scrollTop > sectionsPos[i]) {
          nextSectionPos = sectionsPos[i];
          nextSectionIndex = i;
          break;
        }
      }
      if (nextSectionPos === undefined) return;
      $htmlBody.animate({
        scrollTop: nextSectionPos,
      }, animDuration)
    }

    $sections.removeClass('inview');
    $sections.eq(nextSectionIndex).addClass('inview');
    if ( $('.main-section.inview').length || $('.perfomance-section-title.inview').length ) {
      $('.header-hamburger').addClass('white');
    } else {
      $('.header-hamburger').removeClass('white');
    }

    $this.off('mousewheel', sectionScroll);
    setTimeout(function(){
      $this.on('mousewheel', sectionScroll);
    }, animDuration);
  }

  $window.on('mousewheel', sectionScroll);

  if ( IS_TOUCH_DEVICE ) {
    $window.swipe({
      swipeUp: function(event, direction, distance, duration) {
        var scrollTop = Math.round($window.scrollTop()),
        animDuration = 300,
        nextSectionPos,
        nextSectionIndex = null;

        for (var i = 0; i < sectionsPos.length; i++) {
          if (scrollTop < sectionsPos[i]) {
            nextSectionPos = sectionsPos[i];
            nextSectionIndex = i;
            break;
          }
        }
        if (nextSectionPos === undefined) return;
        $htmlBody.stop(true, true).animate({
          scrollTop: nextSectionPos,
        }, animDuration);

        $sections.removeClass('inview');
        $sections.eq(nextSectionIndex).addClass('inview');
        if ( $('.main-section.inview').length || $('.perfomance-section-title.inview').length ) {
          $('.header-hamburger').addClass('white');
        } else {
          $('.header-hamburger').removeClass('white');
        }
      },
      swipeDown: function(event, direction, distance, duration) {
        var scrollTop = $window.scrollTop(),
        animDuration = 300,
        nextSectionPos,
        nextSectionIndex = null;

        for (var i = sectionsPos.length - 1; i >= 0; i--) {
          if (scrollTop > sectionsPos[i]) {
            nextSectionPos = sectionsPos[i];
            nextSectionIndex = i;
            break;
          }
        }
        if (nextSectionPos === undefined) return;
        $htmlBody.animate({
          scrollTop: nextSectionPos,
        }, animDuration)

        $sections.removeClass('inview');
        $sections.eq(nextSectionIndex).addClass('inview');
        if ( $('.main-section.inview').length || $('.perfomance-section-title.inview').length ) {
          $('.header-hamburger').addClass('white');
        } else {
          $('.header-hamburger').removeClass('white');
        }
      },
      threshold: 50
    });
  }

})();
